import React, { useEffect, useState } from "react";
import fetch from "node-fetch";
import { useDebouncedCallback } from "use-debounce";
import {
  FaSun,
  FaMoon,
  FaCloudShowersHeavy,
  FaSnowflake,
  FaCloud,
  FaWind,
  FaCloudSun,
  FaCloudMoon
} from "react-icons/fa";
import {
  Container,
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardHeader,
  Form,
  Label,
  Input,
  Button
} from "reactstrap";
import moment from "moment";
import "bootswatch/dist/minty/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";

const SearchBar = props => {
  const debouncedSearch = useDebouncedCallback(
    debouncedCity => props.searchCity(debouncedCity),
    1000
  );

  function handleSubmit(event) {
    event.preventDefault();
    debouncedSearch.flush();
  }

  return (
    <Form inline onSubmit={handleSubmit}>
      <Input
        type="text"
        onChange={e => debouncedSearch.callback(e.target.value)}
        placeholder="City"
        data-testid="city-input"
      />
      <Button type="submit" color="primary">
        Search
      </Button>
    </Form>
  );
};

const icons = {
  "01d": FaSun,
  "01n": FaMoon,
  "09d": FaCloudShowersHeavy,
  "13d": FaSnowflake,
  wind: FaWind,
  "50d": FaCloud,
  "03d": FaCloud,
  "02d": FaCloudSun,
  "02n": FaCloudMoon
};

const Day = ({ day }) => {
  const Icon = icons[day.weather[0].icon] || FaCloud;

  return (
    <Col sm={3} className="d-flex">
      <Card className="flex-fill mb-4">
        <CardHeader className="text-center text-white bg-primary">
          <Icon size="10em" />
        </CardHeader>
        <CardBody>
          <CardTitle>{moment.unix(day.dt).format("L")} </CardTitle>
          <CardSubtitle>{day.weather[0].description}</CardSubtitle>
          <CardText>
            {day.temp.max}&deg;C / {day.temp.min}&deg;C
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

const App = () => {
  const [daily, setDaily] = useState({ daily: [] });

  async function searchCity(city) {
    const result = await fetch(`/api/weather?city=${city}`);
    const weatherData = await result.json();
    console.log("WEATHER DATA:", weatherData);
    setDaily(weatherData);
  }

  return (
    <Container>
      <Row>
        <Col>
          <SearchBar searchCity={searchCity} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{daily.summary}</h2>
        </Col>
      </Row>
      <Row>
        {daily.daily.map(day => (
          <Day day={day} key={day.dt} />
        ))}
      </Row>
    </Container>
  );
};

export default App;
